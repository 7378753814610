import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { navigateTo } from '../../helpers/utility';

function CustomCarousel({ items }) {
  function handleOnClick(index) {
    const link = items[index].link;
    navigateTo(link);
  }
  // work around get apps width, now apps max width = 600
  const screenWidth = typeof window !== 'undefined' ? (window.innerWidth > 984 ? 984 : window.innerWidth - 30) : 0;
  const screenHeight = screenWidth / 16 * 7;
  const styles = {
    box: {
      background: 'white',
      cursor: 'pointer'
    },
    img: {
      height: screenHeight,
    }
  }

  return (
    <Carousel
      interval={4000}
      showStatus={false}
      showThumbs={false}
      onClickItem={handleOnClick}
      infiniteLoop
      autoPlay
    >
      {items.map(item =>
        <div style={styles.box} key={item.id}>
          <img style={styles.img} src={item.image}></img>
        </div>
      )}
    </Carousel>
  )
}

export default CustomCarousel;
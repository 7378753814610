import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { navigate } from 'gatsby';
// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { useSelector } from 'react-redux';

import * as Api from '../apis';
import MainLayout from '../components/MainLayout';
import Carousel from '../components/Carousel';
import BlogCard from '../components/BlogCard';
import QuestionnaireList from '../components/QuestionnaireList';

const useStyles = makeStyles(theme => ({
  categoryScroll: {
    width:
      typeof window !== 'undefined'
        ? window.innerWidth - 30 >= 984
          ? 984
          : window.innerWidth - 30
        : 0,
    overflowX: 'auto',
  },
  categoryContainer: {
    display: 'flex',
    width: 'fit-content',
  },
  categoryLogo: {
    width: 55,
    height: 55,
  },
  categoryMenu: {
    cursor: 'pointer',
    '&:active, &:hover': {
      background: '#b3cbe3',
      color: 'white',
      borderRight: '1px solid #d2d2d2',
    },
    width: 140,
    height: 100,
    borderRight: '1px solid #d2d2d2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    fontWeight: 'bold',
    fontSize: 14,
  },
  red: {
    color: 'red',
  },
  quoteBox: {
    border: '2px solid #4677a8',
    width: 200,
    height: 50,
    background: 'white',
    position: 'fixed',
    bottom: 25,
    right: 60,
    cursor: 'pointer',
  },
  triangleRight: {
    bottom: 32,
    position: 'relative',
    left: 196,
    width: 0,
    height: 0,
    borderTop: '10px solid transparent',
    borderLeft: '54px solid #4677a8',
    borderBottom: '10px solid transparent',
  },
  innerTriangleRight: {
    width: 0,
    bottom: 50,
    position: 'relative',
    left: 196,
    height: 0,
    borderTop: '8px solid transparent',
    borderLeft: '46px solid white',
    borderBottom: '8px solid transparent',
  },
}));

const IndexPage = () => {
  const classes = useStyles();
  const [blogs, setBlogs] = useState([]);
  const [banners, setBanners] = useState([]);
  const categories = useSelector(state => state.questionnaire.categories) || [];

  useEffect(() => {
    Api.getBlogs().then(result => {
      setBlogs(result.results.slice(0, 6));
    });
    Api.getBanners().then(result => {
      setBanners(result);
    });
  }, []);

  function handleBlogClick(blog) {
    window.logEvent('select_content', {
      content_type: 'blogs',
      item_id: blog.id + '',
      item_name: blog.topic,
    });
    // trackCustomEvent({
    //   category: "Blogs",
    //   action: "Click",
    //   label: blog.topic,
    // });
    navigate(`/blog/${blog.id}`);
  }

  return (
    <MainLayout categories={categories}>
      <Carousel items={banners} />
      <h3 align='center'>วันนี้ให้เราช่วยดูแลด้านไหนดีคะ?</h3>
      <div className={classes.categoryScroll}>
        <div className={classes.categoryContainer}>
          {categories.map(category => (
            <div
              key={category.id}
              className={classes.categoryMenu}
              onClick={() => navigate(`/blogs?category_id=${category.id}`)}
            >
              <img className={classes.categoryLogo} src={category.logo} />
              <span>{category.name}</span>
            </div>
          ))}
        </div>
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} />
      <Box
        display='flex'
        justifyContent='space-between'
        style={{ margin: '10px 0px' }}
      >
        <h3>บทความที่น่าสนใจ</h3>
        <Button
          variant='contained'
          color='primary'
          className={classes.blogButton}
          onClick={() => navigate('/blogs')}
        >
          บทความทั้งหมด
        </Button>
      </Box>
      <Grid container spacing={2}>
        {blogs.map(blog => {
          return (
            <Grid item xs={12} sm={6} md={4} key={blog.id}>
              <Box>
                <BlogCard
                  title={blog.topic}
                  detail={blog.detail}
                  image={blog.cover.small}
                  onClick={handleBlogClick.bind(null, blog)}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 10 }} />
      <QuestionnaireList />
      <div
        className={classes.quoteBox}
        onClick={() => navigate('/questionnaires')}
      >
        <p align='center'>
          ทำแบบประเมินสุขภาพ <span className={classes.red}>ฟรี!</span>
        </p>
        <div className={classes.triangleRight} />
        <div className={classes.innerTriangleRight} />
      </div>
    </MainLayout>
  );
};

export default IndexPage;
